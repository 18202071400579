.QueueListSpacing {
  display: block;
  margin: 0 auto;
  max-width: 1480px;
}

.QueueListSpacing.Empty {
  /** Subtract the top margin and now playing bar :( ew! */
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.QueueListHeader {
  top: 0;
  margin: 8px 0 0 0;
  text-align: center;
  height: 4em;
  overflow: hidden;

  flex: 0 0 4em;
}
