.ArtistCell {
  box-sizing: content-box;
  max-width: 18%;

  overflow: hidden;
  margin: 0 1% 16px;
  padding: 0 0 8px;
  flex: 1 1 18%;
}

@media (max-width: 1130px) {
  .ArtistCell {
    max-width: 23%;
    flex: 1 1 23%;
  }
}

@media (max-width: 940px) {
  .ArtistCell {
    max-width: 31.3%;
    flex: 1 1 31.3%;
  }
}

@media (max-width: 700px) {
  .ArtistCell {
    max-width: 48%;
    flex: 1 1 48%;
  }
}

@media (max-width: 540px) {
  .ArtistCell {
    max-width: 98%;
    flex: 1 1 98%;
  }
}

.ArtistArt:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.ArtistArt {
  background-image: linear-gradient(to bottom right, #444466, black);
  border-radius: 50%;

  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.ArtistArtImage {
  border-radius: 50%;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ArtistArtImageFade-enter {
  opacity: 0;
}

.ArtistArtImageFade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.ArtistArtImageFade-exit {
  opacity: 1;
}

.ArtistArtImageFade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.ArtistArtClick {
  border-radius: 50%;
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ArtistArtClick.Hover {
  background-color: hsla(0, 0%, 15%, 0.5);
}

.ArtistCaption {
  padding: 0 8px;
}

.ArtistTitle {
  text-align: center;
  margin: 8px 0 0 0;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
