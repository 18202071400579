.AlbumCell {
  box-sizing: content-box;
  max-width: 18%;

  overflow: hidden;
  margin: 0 1% 8px;
  padding: 0 0 8px;
  flex: 1 1 18%;
}

@media (max-width: 1130px) {
  .AlbumCell {
    max-width: 23%;
    flex: 1 1 23%;
  }
}

@media (max-width: 940px) {
  .AlbumCell {
    max-width: 31.3%;
    flex: 1 1 31.3%;
  }
}

@media (max-width: 700px) {
  .AlbumCell {
    max-width: 48%;
    flex: 1 1 48%;
  }
}

@media (max-width: 540px) {
  .AlbumCell {
    max-width: 98%;
    flex: 1 1 98%;
  }
}

.AlbumArt:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.AlbumArt {
  background-image: linear-gradient(to bottom right, #444466, black);
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.AlbumArtImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0 none black;
}

.AlbumArtImageFade-enter {
  opacity: 0;
}

.AlbumArtImageFade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.AlbumArtImageFade-exit {
  opacity: 1;
}

.AlbumArtImageFade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.AlbumArtClick {
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.AlbumArtClick.Hover {
  background-color: hsla(0, 0%, 15%, 0.5);
}

.AlbumCaption {
  padding: 0 8px;
}

.AlbumTitle {
  margin: 8px 0 0 0;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.AlbumArtist {
  color: #555555;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: .015em;

  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
