.SearchPageSpacing {
  display: block;
  margin: 0;
  max-width: 1480px;
}

.SearchPageSpacing.Empty {
  /** Subtract the top margin and now playing bar :( ew! */
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.SearchPageHeader {
  top: 0;
  margin: 8px 0 0 0;
  text-align: center;
  height: 4em;
  overflow: hidden;
}

.SearchInput {
  border: 1px solid black;
  font-size: 14pt;

  /* Why is border-box not taking effect here? */
  width: calc(100% - 52px);

  margin: 0 26px 32px;
  padding: .5em;
}

.SearchEmptyResult {
  text-align: center;

  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 16px;
  line-height: 22px;

  margin: 4px 32px 0;
}
