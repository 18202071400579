.ArtistListSpacing {
  display: block;
  margin: 0 auto;
  max-width: 1480px;
}

.ArtistListHeader {
  top: 0;
  margin: 8px 0 0 0;
  text-align: center;
  height: 4em;
  overflow: hidden;
}
