.EmptyPageContentContainer {
  flex-grow: 1;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.EmptyPageContentMessage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  height: 160px;
  /* background-color: chartreuse; */
}

.EmptyPageContentText {
  text-align: center;

  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 16px;
  line-height: 22px;

  margin: 0 32px 12px;
}

.EmptyPageContentText.Emoji {
  font-size: 60px;
  line-height: 60px;
}
