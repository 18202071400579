.LoginPage {
  background-color: #eeeeff;

  min-height: 100vh;

  padding: 4em 1em;
}

.LoginCenter {
  max-width: 430px;
  margin: 0 auto;
}

.LoginInput {
  border: 1px solid black;
  font-size: 14pt;

  display: block;
  width: 100%;

  margin: 0 0 1.2em;
  padding: .5em;
}

.LoginButton {
  font-size: 14pt;

  display: inline-block;
  width: 100%;

  margin: 8px 0 1.2em 0;
  padding: .6em 1.3em;
}

.ErrorMessage {
  background-color: rgba(255, 0, 0, 0.349);
  padding: 8px;
}
