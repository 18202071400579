* {
  box-sizing: border-box;
}

.App {
  width: 100%;
  min-height: 100vh;
}

button, input[type="submit"] {
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-align: center;
  background: #0000ff;
  background-color: #0000ff;
  border: 2px solid black;
  cursor: pointer;
}

button:hover, input[type="submit"]:hover {
  color: white;
  background-color: #0000cc;
}

button:active, input[type="submit"]:active {
  color: white;
  background-color: #000099;
}
