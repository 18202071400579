.AlbumTrackListSpacing {
  display: block;
  margin: 0 auto;
  max-width: 1480px;
}

.AlbumTrackListHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  top: 0;
  padding: 28px 0 12px 28px;
}

.AlbumTrackListArt {
  background-image: linear-gradient(to bottom right, #444466, black);
  width: 150px;
  min-width: 150px;
  height: 150px;
  min-height: 150px;
}

.AlbumTrackListArtImage {
  width: 100%;
  height: 100%;
  border: 0 none black;
}

.AlbumTrackListArtImageFade-enter {
  opacity: 0;
}

.AlbumTrackListArtImageFade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.AlbumTrackListArtImageFade-exit {
  opacity: 1;
}

.AlbumTrackListArtImageFade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.AlbumTrackListInfo {
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.AlbumTrackListTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.AlbumTrackListArtist {
  color: #555555;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: .015em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding: 0px 0 0 0;
  margin: 4px 0 4px 0;
  box-sizing: border-box;
}

.AlbumTrackListThirdLine {
  color: #555555;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: .015em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding: 0;
  box-sizing: border-box;
}

.AlbumTrackListControls {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.AlbumTrackListButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.AlbumTrackListButton {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin: 0 8px 0 0;

  padding: 0 9px 0 9px;
}

.AlbumTrackListButton.SmallButtonIcon {
  padding: 0 6px 0 6px;
}

.AlbumTrackListButton svg {
  width: 100%;
  height: 100%;
}

.AlbumTrackListMenuButton {

  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin: 0 8px 0 0;

  padding: 0 0 20px 0;
}

.AlbumTrackListMenuButtonText {
  font-size: 32px;
  line-height: 30%;
}

.AlbumTrackListLink {
  color: #555555;
  text-decoration: none;
}

.AlbumTrackListLink:hover {
  color: #0000ff;
  text-decoration: underline;
}
