.ArtistAlbumListSpacing {
  display: block;
  margin: 0 auto;
  max-width: 1480px;
}

.ArtistAlbumListHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  top: 0;
  padding: 28px 0 12px 28px;
}

.ArtistAlbumListArt {
  background-image: linear-gradient(to bottom right, #444466, black);
  border-radius: 50%;

  width: 150px;
  min-width: 150px;
  height: 150px;
  min-height: 150px;
}

.ArtistAlbumListArtImage {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.ArtistAlbumListArtImageFade-enter {
  opacity: 0;
}

.ArtistAlbumListArtImageFade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.ArtistAlbumListArtImageFade-exit {
  opacity: 1;
}

.ArtistAlbumListArtImageFade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.ArtistAlbumListInfo {
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
}

.ArtistAlbumListTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.ArtistAlbumListArtist {
  color: #555555;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: .015em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding: 0px 0 0 0;
  margin: 4px 0 4px 0;
  box-sizing: border-box;
}

.ArtistAlbumListThirdLine {
  color: #555555;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: .015em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding: 0;
  box-sizing: border-box;
}

.ArtistAlbumListControls {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.ArtistAlbumListButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ArtistAlbumListButton {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin: 0 8px 0 0;

  padding: 0 9px 0 9px;
}

.ArtistAlbumListButton.SmallButtonIcon {
  padding: 0 6px 0 6px;
}

.ArtistAlbumListButton svg {
  width: 100%;
  height: 100%;
}

