.NowPlayingBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  padding: 0 16px;
}

.NowPlayingBarLeft {
  width: 30%;
  min-width: 180px;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.NowPlayingBarCenter {
  width: 40%;
  min-width: 316px;
  max-width: 722px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.NowPlayingBarRight {
  width: 30%;
  min-width: 180px;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.NowPlayingArtwork {
  background-image: linear-gradient(to bottom right, #444466, black);
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}

.NowPlayingArtworkImage {
  width: 100%;
  height: 100%;
  border: 0 none black;
}

.NowPlayingArtworkImageFade-enter {
  opacity: 0;
}

.NowPlayingArtworkImageFade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.NowPlayingArtworkImageFade-exit {
  opacity: 1;
}

.NowPlayingArtworkImageFade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.NowPlayingTitle {
  text-overflow: ellipsis;
  white-space: nowrap;

  display:flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin: 0 14px;
  padding: 0 0 0 0;
  overflow: hidden;
}

.NowPlayingTitleTitle {
  text-overflow: ellipsis;
  overflow: hidden;
}

.NowPlayingTitleSubtitle {
  text-overflow: ellipsis;
  overflow: hidden;
}

.NowPlayingTitleSubtitleLink {
  color: #555555;
  text-decoration: none;
}

.NowPlayingTitleSubtitleLink:hover {
  color: #0000ff;
  text-decoration: underline;
}

.ProgressBar {
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ProgressBarInput {
  width: 100%;
}

.ProgressBarTime {
  font-size: 11px;
  letter-spacing: .015em;
  min-width: 40px;
  text-align: center;
}

.PlayerControls {
  width: 112px;

  margin-top: 5px;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.PlayerButton {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin: 0 4px;

  padding: 0 9px 0 9px;
}

.PlayerButton.Highlighted {
  color: #0000ff;
  background-color: white;
}

.PlayerButton.Highlighted:hover {
  color: #0000ff;
  background-color: #eeeeee;
}

.PlayerButton.Highlighted:active {
  color: #0000ff;
  background-color: #cccccc;
}

.PlayerButton.Disabled {
  border-color: #c9c9dd;
  color: #c9c9dd;
  background-color: #ebebff;
  cursor: auto;
}

.PlayerButton.SmallButtonIcon {
  padding: 0 6px 0 6px;
}

.PlayerButton svg {
  width: 100%;
  height: 100%;
}

.SecondaryControls {

  width: 180px;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.VolumeControl {
  display: flex;
  align-items: center;
}

.MuteToggleButton {
  width: 32px;
  height: 32px;
  flex-shrink: 0;

  padding: 5px;
}

.MuteToggleButton svg {
  color: white;
  width: 100%;
  height: 100%;
}

.VolumeInput {
  width: 100%;

  margin: 0 8px 0 8px;
}

.PlayerSpinnerContainer {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin: 0 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes PlayerSpinner {
  to {
    transform: rotate(360deg);
  }
}

.PlayerSpinner {
  content: '';
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #b7b7c7;
  border-top-color: blue;
  animation: PlayerSpinner .6s linear infinite;
}
