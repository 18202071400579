.ListenPage {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;

  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "nav-bar main-view"
    "now-playing-bar now-playing-bar";
}

.NavBar {
  background-color: #f9f9ff;

  width: 200px;
  min-height: 0;
  grid-area: nav-bar;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;

  padding: 0 24px;
}

.MainView {
  background-image: linear-gradient(to top, #ababff, #e9e9ff);

  width: 100%;
  min-height: 0;
  grid-area: main-view;
  margin: 0;
  padding: 0;

  overflow-y: scroll;
  overflow-x: hidden;
}

.NowPlayingBar {
  background-color: #ebebff;

  width: 100%;
  height: 90px;
  grid-area: now-playing-bar;
}

.NavBarTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.NavBarTitleName {
  margin: 21px 0;
}

.AccountInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
}

.NavBarMenu {
  flex-grow: 1;
}

.NavBarMenuItem {
  padding: 8px 0;
  margin: 2px;
}

.NavBarMenuItemSpacer {
  padding: 12px 0;
  margin: 2px;
}

.NavBarMenuItem.Disabled {
  color: #cccccc;
  cursor: auto;
  padding: 8px 0;
  margin: 2px;
}

.NavBarMenuItem > a {
  color: #666666;
  text-decoration: none;
}

.NavBarMenuItem > a:hover {
  color: #333333;
  text-decoration: none;
}

.NavBarMenuItem.Selected > a {
  color: black;
  text-decoration: underline;
}

.NavBarFooter {
  margin: 16px 0 0 0;
  padding: 0 0 8px 0;
}

.UserId {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.LogOutButton {
  width: 100%;
  height: 32px;
  margin: 8px 0;
}

.LogOutButton:hover {
  color: white;
  background-color: #0000ee;
}

.LogOutButton:active {
  color: white;
  background-color: #0000cc;
}

/* https://css-tricks.com/considerations-styling-modal/ */
.Modal {
  color: black;
  background-color: #ebebff;
  box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);

  z-index: 11;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 260px;
  max-width: 100%;
  max-height: 100%;
}

.Modal.Closed {
  display: none;
}

.ModalScroll {
  text-align: center;
  width: 100%;
  flex-grow: 1;
  padding: 16px 16px 8px 16px;
  overflow: auto;
}

.ModalButtons {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;

  padding: 8px 0 8px 8px;
}

.ModalButton {
  flex-grow: 1;
  height: 100%;
  margin: 0 8px 0 0;
}

.ModalOverlay {
  background-color: hsla(0, 0%, 15%, 0.5);

  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ModalOverlay.Closed {
  display: none;
}
