.TrackCell {
  display: inline;
}

.TrackCellRow {
  outline: none;
  background-color: none;

  display: flex;
  height: 3.4em;
  list-style-type: none;
  position: relative;
}

.TrackCellRowHover {
  background-color: hsla(0, 0%, 25%, 0.1);
}

.TrackCellColumn {
  display: block;
  height: 100%;
}

.TrackCellColumn.Action {
  width: 3em;
  min-width: 3em;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 0;
}

.TrackCellAction {
  width: 1em;
  height: 1em;
  cursor: pointer;
}

.TrackCellAction.Disabled {
  cursor: auto;
}

.TrackCellAction svg {
  color: #000000;
  width: 100%;
  height: 1em;
}

.TrackCellRowSelected .TrackCellAction svg {
  color: blue;
}

.TrackCellColumn.Title {
  font-size: 16px;
  flex: 1 1 0;
  width: 0;
  min-width: 400px;
  overflow: hidden;
}

.TrackCellTitle {
  color: black;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: .015em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding: 6px 0 0 0;
  box-sizing: border-box;
}

.TrackCellRowSelected .TrackCellTitle  {
  color: blue;
}

.TrackCellSecondLine {
  color: #555555;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: .015em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;

  padding: 0px 0 0 0;
  box-sizing: border-box;
}

.TrackCellSeparator {
  padding: 0 8px;
}

.TrackCellLink {
  color: #555555;
  text-decoration: none;
}

.TrackCellLink:hover {
  color: #0000ff;
  text-decoration: underline;
}

.TrackCellColumn.MenuButton {
  width: 3em;
  min-width: 3em;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 0;
  margin: 4px 24px 0;
}

.TrackCellMenuButton {
  width: 100%;
  height: 50%;
  cursor: pointer;

  text-align: center;
  line-height: 20%;
  font-size: 32px;
}

.TrackCellMenuButton.Hidden {
  display: none;
}

.TrackCellMenuButton:hover {
  color: blue;
}

.TrackCellColumn.Duration {
  color: #555555;
  padding: 6px 0 0 0;
  flex-shrink: 0;
  min-width: 4em;
}
