.ArtistListContainer {
  display: block;
  position: relative;
}

.ArtistList {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  
  margin-top: 16px;
  padding: 0 16px;
}

.ArtistListTail {
  margin: 0 1%;
  flex-grow: 2;
  flex-basis: auto;
  height: 0;
}
