.ContextMenuContainer {
  position: relative;
}

.ContextMenu {
  background-color: white;
  width: 150px;
  z-index: 9;
  box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.2);
  position: absolute;
}

.ContextMenu.Closed {
  display: none;
}

.ContextMenuOptions {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ContextMenuOption {
  font-size: 16px;
  padding: 8px 8px 8px 8px;
  cursor: pointer;
}

.ContextMenuOption:hover {
  text-decoration: underline;
  background-color: hsla(0, 0%, 25%, 0.1);
}

.ContextMenuOption:active {
  background-color: hsla(0, 0%, 15%, 0.5);
}
